/* eslint-disable */
import {FC} from 'react';
import {Pagination as MuiPagination, PaginationItem, Box, useTheme, useMediaQuery} from '@mui/material';
import {ArrowButtons} from 'modules/theme/components/paginator/partials/ArrowButtons';
import {ScrollToTopBtn} from 'modules/theme/components/paginator/partials/ScrollToTopBtn';

interface IPaginator {
    isDisabled?: boolean;
    currentPage: number;
    totalPageCount: number;
    showScrollToTopBtn?: boolean;
    onChange: (newPage: number) => void;
}

export const Paginator: FC<IPaginator> = ({currentPage, totalPageCount, onChange, isDisabled}) => {
    const {breakpoints} = useTheme();
    const isLgDown = useMediaQuery(breakpoints.down('lg'));
    const siblingCount = isLgDown || (!isLgDown && currentPage === 1) ? 0 : 0;

    return <Box
        display="flex"
        width={'100%'}
        alignItems="center"
        position="relative"
        justifyContent="center"
        flexDirection={{xs: 'column', md: 'row'}}
        data-test-id="paginatorWithArrowButtons"
    >
        <Box position="absolute" left={0} display={{xs: 'none', md: 'block'}}>
            <ScrollToTopBtn/>
        </Box>

        <ArrowButtons
            onChange={onChange}
            isDisabled={isDisabled}
            currentPage={currentPage}
            totalPageCount={totalPageCount}
        />

        <MuiPagination
            hidePrevButton
            hideNextButton
            siblingCount={siblingCount}
            page={currentPage}
            disabled={isDisabled}
            count={totalPageCount}
            data-test-id="paginator"
            sx={{
                right: 0,
                display: 'inline-block',
                position: {xs: 'inherit', md: 'absolute'},
            }}
            renderItem={item => <PaginationItem {...item}/>}
            onChange={(e, page) => currentPage !== page && onChange(page)}
        />
    </Box>
}
