import {FC} from 'react';
import {Box} from '@mui/material';
import {Button} from 'modules/theme/components/button/Button';
import {Svg} from 'modules/theme/components/svg/Svg';
import {Text} from 'modules/theme/components/text/Text';

import ArrowRight from 'modules/theme/components/icons/basic/TintArrowRight.svg';
import ArrowLeft from 'modules/theme/components/icons/basic/TintArrowLeft.svg';

interface IArrowButton {
    currentPage: number;
    onChange: (newPage: number) => void;
    direction: 'back' | 'forward';
    isDisabled?: boolean;
    testId?: string;
}

export const ArrowButton: FC<IArrowButton> = ({ currentPage, onChange, direction, isDisabled, testId }) => {
    const isDirectionForward = direction === 'forward';
    const nextPage = isDirectionForward ? currentPage + 1 : currentPage - 1;

    return <Button
        onClick={() => onChange(nextPage)}
        fullWidth
        secondary
        disabled={isDisabled}
        testId={testId}
        text={
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexDirection={isDirectionForward ? 'row-reverse' : 'row'}
                gap={1}
            >
                <Svg icon
                    component={isDirectionForward ? ArrowRight : ArrowLeft}
                    color='backgroundPrimary'
                />
                <Text
                    variant='body2'
                    semibold
                    inheritColor
                >
                    {isDirectionForward ? 'Ďalšie' : 'Predošlé'}
                </Text>
            </Box>
        }
    />;
};
